import { getJson } from 'utils/http/getJson';
import { postJson } from 'utils/http/postJson';
import { putJson } from 'utils/http/putJson';

import { OrgDashboardApiEvent } from './types/OrgDashboardApiEvent';

export function createEvent(
  orgId: string,
  payload: Record<string, any>, // eslint-disable-line @typescript-eslint/no-explicit-any
): Promise<{
  event: OrgDashboardApiEvent;
}> {
  return postJson(`/data/orgdashboard/${orgId}/events`, payload);
}

export function validateEvent(
  orgId: string,
  payload: Record<string, any>, // eslint-disable-line @typescript-eslint/no-explicit-any
): Promise<{
  event: OrgDashboardApiEvent;
}> {
  return postJson(`/data/orgdashboard/${orgId}/events-validation`, payload);
}

export function updateEvent(
  orgId: string,
  payload: Record<string, any>, // eslint-disable-line @typescript-eslint/no-explicit-any
): Promise<{
  event: OrgDashboardApiEvent;
}> {
  return putJson(`/data/orgdashboard/${orgId}/events/${payload.id}`, payload);
}

export function fetchEvent(
  orgId: string,
  eventId: string,
): Promise<OrgDashboardApiEvent> {
  return getJson<{ event: OrgDashboardApiEvent }>(
    `/data/orgdashboard/${orgId}/events/${eventId}`,
  ).then(({ event }) => event);
}

export function markEventActivityViewed(
  orgId: string,
  eventId: string,
): Promise<OrgDashboardApiEvent> {
  const url = `/data/orgdashboard/${orgId}/events/${eventId}/mark-activity-viewed`;
  return postJson(url);
}
