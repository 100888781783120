import styled from 'styled-components';

import { LegacyButton as Button } from 'components/Button/LegacyButton';
import { colors, fontWeights, fonts, transition } from 'theme/theme';

// TODO update to new tertiary button

export const LegacyTertiaryButton = styled(Button)<{
  $danger?: boolean;
  $grey?: boolean;
}>`
  font-family: ${fonts.sans};
  font-weight: ${fontWeights.semiBold};
  font-size: 18px;
  color: ${(props) =>
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-nested-ternary
    props.$danger
      ? colors.alertRed
      : props.$grey
        ? colors.lightContentGrey
        : colors.brandBlue};
  transition: all ${transition};

  &:hover,
  &:focus {
    color: ${(props) =>
      props.$danger ? colors.alertRedHover : colors.hoverBlue};
  }

  &:hover {
    text-decoration: underline;
  }
`;
