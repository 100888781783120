import { Dispatch } from '@reduxjs/toolkit';

import {
  deleteListing as apiDeleteListing,
  fetchListing as apiFetchListing,
  hideListing as apiHideListing,
  unhideListing as apiUnhideListing,
} from 'modules/orgDashboard/api/orgDashboardApiListing';
import { MainStoreMainSearchAction } from 'store/mainSearch/mainSearch';
import { setError } from 'zustand-stores/errorStore';

import {
  FetchListingRequestAction,
  FetchListingSuccessAction,
  MainStoreOrgDashboardListingsAction,
  MainStoreOrgDashboardListingsState,
} from './listings';

const fetchListingRequest = (listingId: string): FetchListingRequestAction => ({
  type: 'ORG_DASHBOARD_FETCH_LISTING_REQUEST',
  listingId,
});

const fetchListingSuccess = (
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  listing: Record<string, any>,
): FetchListingSuccessAction => ({
  type: 'ORG_DASHBOARD_FETCH_LISTING_SUCCESS',
  listing,
});

export function fetchListing({
  orgId,
  listingType,
  listingId,
  force,
}: {
  orgId: string;
  listingType: 'JOB' | 'INTERNSHIP' | 'VOLOP' | 'EVENT';
  listingId: string;
  force?: boolean;
}) {
  return async (
    dispatch: Dispatch<MainStoreOrgDashboardListingsAction>,
    getState: () => {
      orgDashboard: { listings: MainStoreOrgDashboardListingsState };
    },
  ) => {
    const state = getState();
    const listing = state.orgDashboard.listings.byId[listingId];

    if (!listing || force) {
      dispatch(fetchListingRequest(listingId));
      try {
        const fetchedListing = await apiFetchListing(
          orgId,
          listingType,
          listingId,
        );
        dispatch(fetchListingSuccess(fetchedListing));
        return fetchedListing;
      } catch (error) {
        setError(error as Error);
      }
    }
  };
}

export const updateListing = fetchListingSuccess;

export function hideListing({
  orgId,
  listingType,
  listingId,
}: {
  orgId: string;
  listingType: 'JOB' | 'INTERNSHIP' | 'VOLOP';
  listingId: string;
}) {
  return async (
    dispatch: Dispatch<
      MainStoreOrgDashboardListingsAction | MainStoreMainSearchAction
    >,
  ) => {
    try {
      const listing = await apiHideListing({
        orgId,
        listingType,
        listingId,
      });
      dispatch(updateListing(listing));
    } catch (error) {
      setError(error as Error);
    }
  };
}

export function unhideListing({
  orgId,
  listingType,
  listingId,
}: {
  orgId: string;
  listingType: 'JOB' | 'INTERNSHIP' | 'VOLOP';
  listingId: string;
}) {
  return async (
    dispatch: Dispatch<
      MainStoreOrgDashboardListingsAction | MainStoreMainSearchAction
    >,
  ) => {
    try {
      const listing = await apiUnhideListing({
        orgId,
        listingType,
        listingId,
      });

      dispatch(updateListing(listing));
    } catch (error) {
      setError(error as Error);
    }
  };
}

export function deleteListing({
  orgId,
  listingType,
  listingId,
}: {
  orgId: string;
  listingType: 'JOB' | 'INTERNSHIP' | 'VOLOP';
  listingId: string;
}) {
  return async (
    dispatch: Dispatch<
      MainStoreOrgDashboardListingsAction | MainStoreMainSearchAction
    >,
  ) => {
    try {
      const deletedListing = await apiDeleteListing({
        orgId,
        listingType,
        listingId,
      });
      dispatch(updateListing(deletedListing));
    } catch (error) {
      setError(error as Error);
    }
  };
}

export function resetListing() {
  return (dispatch: Dispatch<MainStoreOrgDashboardListingsAction>) =>
    dispatch({
      type: 'ORG_DASHBOARD_RESET_LISTING',
    });
}
